import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { ApiResponse } from "../types/Types";
import moment from "moment";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { interaction } from "../Tracking";

import { useConfig } from "../context";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Paper, Typography, Grid, Box } from "@mui/material";
import { FetchTimeseries } from "../helpers/FetchData";
import mockData from "../helpers/Mockdata.json";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { useTheme } from "@mui/material/styles";

const CustomTooltip = ({ active, payload, label }: any) => {
  const theme = useTheme();
  if (active && payload && payload.length) {
    return (
      <Paper
        sx={{
          height: "auto",
          position: "relative",
          p: 1,
        }}
        elevation={1}
      >
        <div
          style={{
            margin: "-4px -8px 0 -8px",
            borderRadius: "3px 3px 0 0",
            paddingLeft: "5px",
            borderBottom: `1px solid ${theme.palette.divider}`,
            display: "grid",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: "5px",
          }}
        >
          <Typography variant="subtitle2">
            {moment.unix(label / 1000).format("YYYY-MM-DD HH:mm")}
          </Typography>
        </div>
        {payload.map((item: any, index: any) => {
          return (
            <Typography
              variant="body2"
              key={item.name + label}
              style={{ color: item.color }}
              className="label"
            >
              {`${item.name}: ${parseFloat(item.value).toPrecision(3)}`}
            </Typography>
          );
        })}
      </Paper>
    );
  }

  return null;
};

export const Chart = (props: any) => {
  const apiVersionSelect = props.apiVersionSelect;
  const [searchParams] = useSearchParams();
  const { selectedMetricValue, onSelectedMetricChange } = props;

  const { config } = useConfig();

  const getConversionLabel = (type: string) => {
    switch (type) {
      case "means":
        return "Value per visitor";
      case "count":
        return "Conversions per visitor";
      case "items":
        return "Items per visitor";
      default:
        return "Conversion Rate";
    }
  };

  const metricValues = [
    { value: "mean_value", label: getConversionLabel(props.type) },
    { value: "pct_change", label: "Improvement" },
    { value: "num_diff", label: "Total conversions" },
  ];

  useEffect(() => {
    fetchTimeseries();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [hasProperMetric, setHasProperMetric] = useState(true);

  const [timeseries, setTimeseries] = useState([
    {
      experimentId: 0,
      variationId: 0,
      metric: "",
      name: "",
      data: [
        {
          pct_change: null,
          mean_value: 0,
          num_diff: 0,
          unix_time_stamp: 0,
        },
      ],
    },
  ]);

  const fetchTimeseries = () => {
    if (props.mockEnabled) {
      const timeseries = mockData.graph_data.response.results
        .filter((o: any) => o.metricIndex === props.metricIndex)
        .map((e: any) => {
          const varName = props.getVariationName(e.variationId);
          return { name: varName, ...e };
        });
      timeseries.sort((a: any, b: any) => a.variationId - b.variationId);
      setTimeseries(timeseries);
    } else {
      FetchTimeseries(
        "v1",
        props.experimentId,
        props.metricIndex,
        searchParams.get("s"),

        (result: ApiResponse) => {
          const timeseries = result.response.results.map((e: any) => {
            const varName = props.getVariationName(e.variationId);
            return { name: varName, ...e };
          });
          timeseries.sort((a: any, b: any) => a.variationId - b.variationId);
          setTimeseries(timeseries);
        }
      );
    }
  };

  useEffect(() => {
    if (!Object.keys(timeseries[0].data[0]).includes(selectedMetricValue)) {
      setHasProperMetric(false);
    } else {
      setHasProperMetric(true);
    }
  }, [timeseries, selectedMetricValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const [currentLabel, setCurrentLabel] = useState(
    getConversionLabel(props.type)
  );

  const changeMetricHandler = (event: SelectChangeEvent) => {
    interaction("select_metric", [
      {
        name: "graph_location",
        stringValue: props.name,
      },
      {
        name: "metric_value",
        stringValue:
          metricValues?.find((i) => i.value === event.target.value)?.label ||
          "",
      },
    ]);

    onSelectedMetricChange(event.target.value as string);

    setCurrentLabel(
      metricValues?.find((i) => i.value === event.target.value)?.label || ""
    );
  };

  const getYlabel = () => {
    const suffix =
      props.event === "purchase" && props.type === "means" ? " (SEK)" : "";
    return currentLabel + suffix;
  };

  if (timeseries.length === 0) {
    return (
      <Box
        sx={{
          marginTop: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <DoDisturbIcon color="disabled" sx={{ fontSize: 140 }} />
        <Typography variant="overline">No data vailable</Typography>
      </Box>
    );
  } else {
    const graphData: any = [];
    const fullDates: any = [];

    let expLength = 0;

    const startDate = moment(timeseries[0].data[0].unix_time_stamp);
    const endDate = moment(
      timeseries[0].data[timeseries[0].data.length - 1].unix_time_stamp
    );
    expLength = Math.round(moment.duration(endDate.diff(startDate)).asDays());

    timeseries[0].data.forEach((i, index) => {
      let interval;
      switch (true) {
        case expLength > 12:
          interval = 1;
          break;
        case expLength > 5:
          interval = 3;
          break;
        default:
          interval = 5;
          break;
      }
      const date = i.unix_time_stamp;

      if (index % interval === 0) {
        fullDates.push(date);
      }

      const name = timeseries[0].name;
      const obj = { date: date } as any;

      const mean_value =
        props.type === "prop" ? i.mean_value * 100 : i.mean_value;
      obj[name] = {
        mean_value: mean_value ? parseFloat(mean_value.toFixed(2)) : null,
        pct_change: i.pct_change,
        num_diff: i.num_diff,
      };

      timeseries.forEach((o, index) => {
        if (index > 0) {
          const out: any = o.data.find((a) => a.unix_time_stamp === date);
          const mean_value =
            props.type === "prop" ? out.mean_value * 100 : out.mean_value;
          obj[o.name] = {
            mean_value: mean_value ? parseFloat(mean_value.toFixed(2)) : null,
            pct_change: out.pct_change,
            num_diff: out.num_diff,
          };
        }
      });

      graphData.push(obj);
    });
    (window as any).graphData = graphData;

    const tickFormatter = (unixTime: number) => {
      const roundedTime =
        Math.round(unixTime / 1000 / 60 / 30) * 30 * 60 * 1000;
      const formattedTime = moment(roundedTime).format("HH:mm");
      const formattedDate = moment(roundedTime).format("MMM DD");
      if (fullDates.includes(unixTime)) {
        return formattedDate;
      } else {
        return formattedTime;
      }
    };

    const yTickFormatter = (value: any) => {
      const suffix: any = /Improvement|Conversion Rate/.test(currentLabel)
        ? "%"
        : "";
      return currentLabel === "Total conversions" && value % 1 !== 0
        ? ""
        : value + suffix;
    };

    return (
      <>
        <Grid container justifyContent="flex-end" spacing={4} columns={2}>
          {config.user.level >= 100 && (
            <Grid item xs="auto">
              <FormControl
                sx={{ minWidth: 220 }}
                style={{
                  zIndex: "1000",
                  marginTop: "25px",
                }}
                variant="outlined"
                size="small"
              >
                <InputLabel id="api-version-label">
                  Choose api version
                </InputLabel>
                {apiVersionSelect}
              </FormControl>
            </Grid>
          )}

          <Grid item xs="auto">
            <FormControl
              sx={{ minWidth: 220 }}
              style={{
                zIndex: "1000",
                marginTop: "25px",
              }}
              variant="outlined"
              size="small"
            >
              <InputLabel id="metric-label">Metric</InputLabel>
              <Select
                labelId="metric-label"
                value={selectedMetricValue}
                onChange={(event: SelectChangeEvent) => {
                  console.log(
                    metricValues.find((i) => i.value === event.target.value)
                      ?.label
                  );
                  changeMetricHandler(event);
                }}
                label="Metric"
              >
                {metricValues
                  .filter(
                    (metricValue: any) =>
                      props.category !== "monitoring" ||
                      metricValue.value !== "pct_change"
                  )
                  .map((metricValue) => (
                    <MenuItem key={metricValue.value} value={metricValue.value}>
                      {metricValue.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {!hasProperMetric ? (
          <Box
            sx={{
              marginTop: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="overline">Metric not implemented</Typography>
          </Box>
        ) : (
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              style={{
                marginTop: "20px",
              }}
              width={730}
              height={250}
              data={graphData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid />
              <XAxis
                angle={-45}
                textAnchor="end"
                interval="preserveStartEnd"
                fontSize={10}
                tickSize={1}
                dataKey="date"
                tickFormatter={tickFormatter}
              />
              <YAxis
                tickFormatter={yTickFormatter}
                type="number"
                domain={["auto", "auto"]}
                label={{
                  style: { textAnchor: "middle" },
                  dx: -10,
                  value: getYlabel(),
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend verticalAlign="bottom" />
              {timeseries.map((item: any, index: number) => {
                return (
                  <Line
                    dot={false}
                    strokeWidth={1}
                    key={`${item.name}`}
                    dataKey={item.name + `[${selectedMetricValue}]`}
                    stroke={`${props.getVariationColor(item.variationId)}`}
                    name={`${item.name}`}
                    isAnimationActive={false}
                    type="monotone"
                  />
                );
              })}
            </LineChart>
          </ResponsiveContainer>
        )}
      </>
    );
  }
};
