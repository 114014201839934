import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { Scorecard } from "./Scorecard";
import { GuardrailMetricsTable } from "./GuardrailMetricsTable";
import React, { useState, useEffect } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import { interaction } from "../Tracking";

interface Variation {
  variationId: number;
  number_bucketed: number;
}
function getBucketedVariationsAndPercentage(
  bucketedVisitors: { total: number; variations: Variation[] },
  targetVariationId: number
): string {
  const targetVariation = bucketedVisitors.variations.find(
    (variation) => Number(variation.variationId) === targetVariationId
  );

  if (!targetVariation) {
    return "-";
  }

  const percentage =
    (targetVariation.number_bucketed / bucketedVisitors.total) * 100;
  return `${Intl.NumberFormat().format(
    targetVariation.number_bucketed
  )}  (${percentage.toFixed(2)}%)`;
}

export const VariationAccordionItem = (props: any) => {
  const {
    successMetrics,
    guardrailMetrics,
    name,
    defaultExpanded,
    variationStatus,
    usersInVariation,
    variationId,
    guardRailFail,
    isSegmented,
  } = props;
  const theme = useTheme();

  let statusColor;
  switch (variationStatus) {
    case "winner":
      statusColor = theme.palette.success.light;
      break;
    case "loser":
      statusColor = theme.palette.error.light;
      break;
    default:
      statusColor = theme.palette.text.secondary;
      break;
  }
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    if (!isSegmented) {
      setExpanded(!expanded);
      interaction("variation_item_state", [
        {
          name: "variation_item_state",
          stringValue: !expanded ? "expanded" : "collapsed",
        },
        {
          name: "variation_item_name",
          stringValue: name,
        },
      ]);
    }
  };

  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <Accordion expanded={expanded} onChange={handleChange} sx={{ mb: 1 }}>
      <AccordionSummary
        sx={{
          borderBottom: `1px solid ${statusColor}`,
          cursor: isSegmented ? "default!important" : "pointer",
        }}
        expandIcon={!isSegmented ? <ExpandMoreIcon /> : null}
      >
        <div className="variation-accordion-item-header">
          <Typography variant="h6">{name}</Typography>
          <Typography variant="body2">
            Users:{" "}
            {getBucketedVariationsAndPercentage(usersInVariation, variationId)}
          </Typography>
          <Grid container>
            <Grid item xs="auto">
              <Typography variant="subtitle1">Status:</Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography
                variant="subtitle1"
                color={statusColor}
                sx={{ ml: 1, textTransform: "capitalize" }}
              >
                <strong>{variationStatus}</strong>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={3} sx={{ padding: "40px" }}>
          <Grid item xs={12} md={6} sm={12} lg={8} container spacing={2}>
            {successMetrics.map((metric: any, key: any) => {
              const stats =
                metric.variations[variationId] &&
                metric.variations[variationId].hasOwnProperty("stats")
                  ? metric.variations[variationId].stats
                  : {
                      statSig: false,
                      statColor: "",
                      statBgColor: "",
                      isWinner: false,
                    };

              return (
                <Grid key={key} item xs={6}>
                  <Scorecard
                    statusColor={
                      stats.statSig
                        ? stats.isWinner
                          ? theme.palette.success.light
                          : theme.palette.error.light
                        : theme.palette.divider
                    }
                    customSx={{ maxHeight: "196px" }}
                    smallHeading={"Sucess metric"}
                    mainHeading={metric.name}
                  >
                    <div className="variation-paper-result">
                      <Typography
                        sx={{
                          color: stats.statSig
                            ? stats.isWinner
                              ? theme.palette.success.main
                              : theme.palette.error.main
                            : theme.palette.text.secondary,
                          typography: { md: "h3", sm: "h4", xs: "h5" },
                        }}
                        variant="h4"
                      >
                        {!isSegmented &&
                        metric.variations[variationId] &&
                        metric.variations[variationId].pct_change > 0
                          ? "+"
                          : ""}
                        {!isSegmented && metric.variations[variationId]
                          ? metric.variations[variationId].pct_change.toFixed(2)
                          : ""}
                        %
                      </Typography>
                    </div>
                  </Scorecard>
                </Grid>
              );
            })}
          </Grid>

          {/* Recent Deposits */}
          <Grid item xs={12} md={6} sm={12} lg={4}>
            <Scorecard
              statusColor={
                guardRailFail === true
                  ? theme.palette.error.light
                  : theme.palette.divider
              }
              mainHeading="Guardrail Metrics"
              customSx={{}}
            >
              {
                <GuardrailMetricsTable
                  variationId={variationId}
                  guardrailMetrics={guardrailMetrics}
                  textColor={statusColor}
                />
              }
            </Scorecard>
          </Grid>
        </Grid>
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        ></Box>
      </AccordionDetails>
    </Accordion>
  );
};
