import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  IconButton,
  Menu,
  MenuItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BarChartIcon from "@mui/icons-material/BarChart";
import DuplicateRuleButton from "../FlagDetail/DuplicateRule";
import DuplicateToRolloutButton from "../FlagDetail/DuplicateToRolloutButton";
import SetArchiveRule from "./SetArchiveRule";
import { useConfig } from "./../context";
import UnArchiveRule from "./UnArchiveRule";
import { interaction } from "../Tracking";

const MoreMenu: React.FC<any> = ({ item }) => {
  const { config } = useConfig();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectItem = (key: string) => {
    interaction(key);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="more-button"
        aria-controls={open ? "more-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "more-button",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleSelectItem("view_results");
          }}
          sx={{ p: 0 }}
        >
          <ListItemButton
            component={Link}
            to={
              "/results/" +
              item.id +
              "?s=" +
              new Date(item.start_time).getTime()
            }
            disabled={Number(item.state) < 30 || item.type === "rollout"}
          >
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText>View results</ListItemText>
          </ListItemButton>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleSelectItem("duplicate_experiment");
          }}
          sx={{ p: 0 }}
          disabled={
            config.user.level < 25 ||
            (config.user.region.length > 0 &&
              config.user.region !== item.region_key)
          }
        >
          <DuplicateRuleButton
            ruleId={item.id}
            flagId={item.flag_id}
            type={item.type}
            disabled={
              config.user.level < 25 ||
              (config.user.region.length > 0 &&
                config.user.region !== item.region_key)
            }
            meta={item.meta}
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleSelectItem("delete_experiment");
          }}
          sx={{ p: 0 }}
          disabled={
            config.user.level < 25 ||
            (config.user.region.length > 0 &&
              config.user.region !== item.region_key)
          }
        >
          {item.archived === 0 ? (
            <SetArchiveRule
              ruleId={item.id}
              flagId={item.flag_id}
              type={item.type}
              disabled={
                (item.state > 0 && item.state < 40) ||
                config.user.level < 25 ||
                (config.user.region.length > 0 &&
                  config.user.region !== item.region_key)
              }
            />
          ) : (
            <UnArchiveRule
              ruleId={item.id}
              flagId={item.flag_id}
              type={item.type}
              disabled={
                (item.state > 0 && item.state < 40) ||
                config.user.level < 25 ||
                (config.user.region.length > 0 &&
                  config.user.region !== item.region_key)
              }
            />
          )}
        </MenuItem>
        <MenuItem
          sx={{ p: 0 }}
          onClick={() => {
            handleSelectItem("create_rollout");
          }}
          disabled={
            item.type !== "ab" ||
            config.user.level < 25 ||
            (config.user.region.length > 0 &&
              (config.user.region !== item.region_key ||
                (item.flag_id !== 22 && item.flag_id !== 93)))
          }
        >
          <DuplicateToRolloutButton item={item} closeParent={handleClose} />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MoreMenu;
