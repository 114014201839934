import React, { memo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Tooltip,
  Autocomplete,
  TextField,
  Switch,
  FormControlLabel,
} from "@mui/material";

import moment from "moment";
import { FetchExperiments } from "../helpers/FetchData";
import { ApiResponse, ExperimentItem } from "../types/Types";
import SearchField from "../components/common/Searchfield";
import ErrorMessage from "../components/common/ErrorMessage";

import { useConfig } from "../context";
import { RangePicker } from "../components/common/DateRangePicker";
import {
  getDefaultBrand,
  setDefaultBrand,
  getDefaultTeam,
  setDefaultTeam,
  getDefaultRegion,
  setDefaultRegion,
  setDefaultState,
  getDefaultState,
  getDefaultType,
  setDefaultType,
} from "../helpers/Helpers";
import Loader from "../components/common/Loader";
import { useDebounce } from "../helpers/useDebounce";
import { useTitle } from "../helpers/useTitle";
import { interaction } from "../Tracking";

const FlagsToolbar: React.FC<any> = memo((props: any) => {
  const [managedTeams, setSetManagedTeams] = useState<
    { id: number; name: string; team_key: string }[]
  >([]);

  const [selectedTeam, setSelectedTeam] = useState({
    id: 0,
    name: "All",
    team_key: "all",
  });

  (window as any).selectedTeam = selectedTeam;

  useEffect(() => {
    if (props.allTeams) {
      const mergedTeams = [
        { id: 0, name: "All", team_key: "all" },
        ...props.allTeams,
      ];
      setSetManagedTeams(mergedTeams);
    }
  }, [props.allTeams]);

  useEffect(() => {
    if (props.team && typeof props.team === "number") {
      const team: any = managedTeams.find((i: any) => i.id === props.team);
      setSelectedTeam(team);
    } else {
      setSelectedTeam({ id: 0, name: "All", team_key: "all" });
    }
  }, [props.team, managedTeams]);

  const handleInputChange = (event: any) => {
    props.setPage(1);
    props.setSearchQuery(event.target.value);
  };

  const handleTypeChange = (event: any) => {
    props.setPage(1);
    props.setType(event.target.value);
    setDefaultType(event.target.value);

    interaction("select_metric", [
      { name: "type", stringValue: event.target.value },
    ]);
  };

  const toggleExcludeRegions = (event: any) => {
    props.setPage(1);
    props.setExcludeRegions(!props.excludeRegions);
    interaction("select_metric", [
      { name: "exclude_regions", stringValue: event.target.value },
    ]);
  };

  const handleTeamChange = (_: any, value: any) => {
    const id = value ? value.id : 0;
    props.setPage(1);
    props.setTeam(Number(id));
    setDefaultTeam(Number(id));
    interaction("select_metric", [
      { name: "team", stringValue: value?.name || "all" },
    ]);
  };

  const handleRegionChange = (event: any) => {
    props.setPage(1);
    props.setRegion(event.target.value);
    setDefaultRegion(event.target.value);
    interaction("select_metric", [
      { name: "region", stringValue: event.target.value },
    ]);
  };
  interface Brand {
    id: number;
    name: string;
  }

  const handleBrandChange = (event: any) => {
    props.setPage(1);
    props.setBrand(Number(event.target.value));
    setDefaultBrand(Number(event.target.value));
    const brandName = (config.brands as Brand[]).find(
      (brand) => brand.id === event.target.value
    )?.name;

    interaction("select_metric", [
      { name: "brand", stringValue: brandName || "all" },
    ]);
  };

  interface State {
    id: number;
    name: string;
    state_id: number;
  }

  const handleStateChange = (event: any) => {
    props.setPage(1);
    props.setState(Number(event.target.value));
    setDefaultState(Number(event.target.value));
    const stateName = (config.rule_states as State[]).find(
      (state) => state.state_id === event.target.value
    )?.name;
    interaction("select_metric", [
      { name: "state", stringValue: stateName || "all" },
    ]);
  };

  const handleDateChange = (event: any) => {
    props.setPage(1);
    props.handleDateChange(event);
    interaction("select_metric", [{ name: "date", stringValue: "selected" }]);
  };

  const { config } = useConfig();

  useTitle("Results");

  if (Object.keys(config).length === 0) return <></>;
  return (
    <Grid container justifyContent="flex-start">
      <Grid item md={config.user.level === 100 ? 2 : 3} xs={6}>
        <SearchField
          value={props.searchQuery}
          handleSearchChange={handleInputChange}
        />
      </Grid>
      {config.user.level === 100 ? (
        <Grid item md={1} xs={6} maxWidth={"80px!important"}>
          <FormControl
            sx={{
              display: config.user.level === 100 ? "inline-flex" : "none",
            }}
          >
            <FormControlLabel
              value="top"
              control={
                <Switch
                  checked={props.excludeRegions}
                  onChange={toggleExcludeRegions}
                  size="small"
                  color="success"
                  sx={{ mt: "-10px" }}
                />
              }
              label={
                <Typography sx={{ paddingBottom: 1 }} variant="caption">
                  No CXS
                </Typography>
              }
              labelPlacement="top"
            />
          </FormControl>
        </Grid>
      ) : (
        <> </>
      )}

      <Grid item md={1} xs={6}>
        <FormControl
          sx={{ m: 1, width: "98%" }}
          size="small"
          className={props.type !== "0" ? "active-filter" : ""}
        >
          <InputLabel id="type-select-label">Type</InputLabel>
          <Select
            value={props.type}
            onChange={handleTypeChange}
            labelId="type-select-label"
            id="type-select"
            label="Type"
          >
            <MenuItem value="0">All</MenuItem>
            <MenuItem value="ab">A/B</MenuItem>
            <MenuItem value="mab">MAB</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={2} xs={6}>
        <FormControl
          sx={{ m: 1, width: "98%" }}
          size="small"
          className={props.brand > 0 ? "active-filter" : ""}
        >
          <InputLabel id="brand-select-label">Brand</InputLabel>
          <Select
            value={config?.brands?.length > 0 ? props.brand : ""}
            onChange={handleBrandChange}
            labelId="brand-select-label"
            id="brand-select"
            label="Brand"
          >
            <MenuItem value="0">All</MenuItem>
            {config?.brands?.map((item: { name: string; id: number }) => {
              return (
                <MenuItem key={item.name} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={2} xs={3}>
        <Grid container spacing={0} direction="column">
          <Grid item xs={props.team === 1 ? 6 : 12}>
            <Autocomplete
              id="demo-simple-select"
              onChange={handleTeamChange}
              value={selectedTeam}
              disablePortal
              options={managedTeams}
              getOptionLabel={(option: any) => {
                const team = managedTeams.find((i: any) => i.id === option.id);
                return team ? team.name : "";
              }}
              sx={{ m: 1, width: "98%" }}
              size="small"
              className={props.team > 0 ? "active-filter" : ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Teams"
                  placeholder="All teams"
                />
              )}
              isOptionEqualToValue={(option, selectedValue) => {
                return option.id === selectedValue.id;
              }}
            />
          </Grid>

          {props.team !== 1 ? (
            <></>
          ) : (
            <Grid item xs={6}>
              <FormControl
                sx={{ m: 1, width: "98%" }}
                className={props.region !== "0" ? "active-filter" : ""}
                size="small"
              >
                <InputLabel id="demo-simple-select-label">Region</InputLabel>
                <Select
                  value={props.region}
                  onChange={handleRegionChange}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Region"
                >
                  <MenuItem value="0">All</MenuItem>

                  {config?.regions.map(
                    (item: { name: string; region_key: string }) => {
                      return (
                        <MenuItem key={item.name} value={item.region_key}>
                          {item.name}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item md={1} xs={3}>
        <FormControl
          sx={{ m: 1, width: "98%" }}
          size="small"
          className={props.state > -1 ? "active-filter" : ""}
        >
          <InputLabel id="demo-simple-select-label">State</InputLabel>
          <Select
            onChange={handleStateChange}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.state}
            label="State"
          >
            <MenuItem value="-1">All</MenuItem>

            {config?.rule_states.map(
              (item: { name: string; id: number; state_id: number }) => {
                if (item.state_id >= 30) {
                  return (
                    <MenuItem key={item.name} value={item.state_id}>
                      {item.name}
                    </MenuItem>
                  );
                } else {
                  return null;
                }
              }
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={3} xs={6}>
        <RangePicker handleDateChange={handleDateChange} />
      </Grid>
    </Grid>
  );
});

const Results: React.FC<{}> = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState<ApiResponse>();
  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [numberOfPages, setnumberOfPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const [team, setTeam] = useState(0);
  const [brand, setBrand] = useState(0);
  const [state, setState] = useState(-1);
  const [type, setType] = useState("0");
  const [excludeRegions, setExcludeRegions] = useState(false);
  const [region, setRegion] = useState("0");
  const [liveDateRange, setLiveDateRange] = useState({ start: "", end: "" });
  const { config } = useConfig();

  const debouncedSearch = useDebounce<string>(searchQuery, 200);
  (window as any).excludeRegions = excludeRegions;
  const handleDateChange = (event: any) => {
    if (event.startDate && event.endDate) {
      const start = moment(event.startDate).format("YYYY-MM-DD HH:mm:ss");
      const end = moment(event.endDate)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      setLiveDateRange({ start: start, end: end });
    } else {
      setLiveDateRange({ start: "", end: "" });
    }
  };

  useEffect(() => {
    setIsLoaded(false);
    const team = getDefaultTeam();
    setTeam(team);
    const brand = getDefaultBrand();
    setBrand(brand);
    const state = getDefaultState();
    setState(state);
    const region = getDefaultRegion();
    setRegion(region);
    const type = getDefaultType();
    setType(type);
    FetchExperiments(
      (output: ApiResponse) => {
        //   console.log(output);
        setIsLoaded(true);
        setData(output);
        //console.log(output.response);
        setnumberOfPages(Math.ceil(output.response.count / pageSize));
      },
      page,
      pageSize,
      debouncedSearch,
      team,
      state,
      region,
      liveDateRange,
      brand,
      type,
      excludeRegions
    );
  }, [
    page,
    pageSize,
    debouncedSearch,
    team,
    state,
    region,
    liveDateRange,
    brand,
    type,
    excludeRegions,
  ]);

  const Experimentlist: React.FC<{}> = () => {
    const navigate = useNavigate();
    /*
    TODO:
      These three following functions should be combined into one univeral, when there is time.
    */
    function getStateName(state_id: number): any | undefined {
      const states = config.rule_states as {
        name: string;
        state_id: number;
      }[];

      const state = states.find((s) => s.state_id === state_id);
      return state ? state : undefined;
    }

    function getTeamName(id: number): any | undefined {
      const teams = config.teams as {
        name: string;
        id: number;
        team_key: string;
      }[];

      const team = teams.find((s) => s.id === id);
      return team ? team : undefined;
    }

    function getRegionName(region_key: string | null): any | undefined {
      if (region_key === null) {
        return "";
      }
      const regions = config.regions as {
        name: string;
        id: number;
        region_key: string | null;
      }[];

      const region = regions.find((s) => s.region_key === region_key);
      return region ? region : undefined;
    }

    const getBrandName = (brandId: number) => {
      const brand = config?.brands?.find((brand: any) => brand.id === brandId);
      const result = brand ? brand["name"] : "all";
      return result;
    };
    /**
     * END
     */

    if (data?.status !== 200) {
      return <ErrorMessage message={data?.message} />;
    } else {
      if (Object.keys(config).length === 0) return <></>; // Waiting for config

      return (
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table
                sx={{
                  minWidth: 650,
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell width={"50%"}>Name</TableCell>
                    <TableCell align="right">State</TableCell>
                    <TableCell align="right">Modified</TableCell>
                    <TableCell align="right">Region</TableCell>
                    <TableCell align="right">Team</TableCell>
                    <TableCell align="right">Brand</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.response.results.map((item: ExperimentItem) => (
                    <TableRow
                      className="experiment-list-row"
                      onClick={(e) => {
                        const cellText = document.getSelection();
                        if (cellText?.type !== "Range") {
                          navigate(
                            `/results/${item.id}?s=${new Date(
                              item.start_time
                            ).getTime()}`
                          );
                        }
                      }}
                      key={item.id}
                    >
                      <TableCell component="th" scope="row">
                        <Typography>{item.name}</Typography>
                        <Tooltip
                          placement="left"
                          enterDelay={800}
                          title={item.hypothesis ? item.hypothesis : ""}
                        >
                          <Typography
                            className="experiment-list-overflow-text"
                            fontSize={12}
                            color="rgb(110 110 110 / 87%)"
                          >
                            {item.hypothesis}
                          </Typography>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="right">
                        <span
                          style={
                            item.state === 30
                              ? {
                                  cursor: "auto",
                                  fontWeight: "bold",
                                  color: "rgba(127, 189, 49)",
                                }
                              : {}
                          }
                        >
                          {getStateName(item.state).name}
                        </span>
                      </TableCell>

                      <TableCell align="right">
                        <Tooltip
                          title={
                            item.updated_at
                              ? `Last modified at: ${moment(
                                  item.updated_at
                                ).format("MMM D HH:mm, YYYY")}`
                              : ""
                          }
                        >
                          <span style={{ cursor: "auto" }}>
                            {moment(item.updated_at).format("MMM D, YYYY")}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title={getRegionName(item.region_key).name}>
                          <span
                            style={{
                              cursor: "auto",
                              textTransform: "uppercase",
                            }}
                          >
                            {item.region_key ?? ""}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip
                          title={
                            (
                              config.teams.find(
                                (team: { id: number; name: string }) =>
                                  team.id === item.team_id
                              ) || { name: "" }
                            ).name
                          }
                        >
                          <span
                            style={{
                              cursor: "auto",
                              textTransform: "uppercase",
                            }}
                          >
                            {getTeamName(item.team_id).team_key}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        {getBrandName(item.brand_id) ?? ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <>
      <FlagsToolbar
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        setTeam={setTeam}
        setBrand={setBrand}
        setRegion={setRegion}
        setType={setType}
        team={team}
        brand={brand}
        region={region}
        type={type}
        setState={setState}
        setPage={setPage}
        state={state}
        config={config}
        handleDateChange={handleDateChange}
        allTeams={config?.teams}
        excludeRegions={excludeRegions}
        setExcludeRegions={setExcludeRegions}
      />

      {isLoaded ? <Experimentlist /> : <Loader />}

      <Pagination
        sx={{
          marginTop: "35px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        count={numberOfPages}
        page={page}
        onChange={(event, value) => setPage(value)}
      />
    </>
  );
};

export default Results;
