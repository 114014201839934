import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { FetchStatsRecent } from "../helpers/FetchData";
import CenteredSpinner from "../components/common/CenteredSpinner";
import HelpToolTip from "../components/common/HelpToolTip";
import { interaction } from "../Tracking";

const RecentExperiments: React.FC<{}> = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [recentData, setRecentData] = useState({ recent: [], topTeams: [] });
  const [topTeamsData, setTopTeamsData] = useState([]);
  const [sortVariation, setSortVariation] = useState(false);
  const [open, setOpen] = useState(false);
  const [openTeams, setOpenTeams] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    FetchStatsRecent((output: any) => {
      setIsLoaded(true);
      setRecentData(output.response);
    });
  }, []);

  useEffect(() => {
    const teams = [...recentData.topTeams];
    const sortProperty: string = sortVariation ? "avg_variations" : "row_count";
    teams.sort((a: any, b: any) => {
      if (a[sortProperty] > b[sortProperty]) {
        return -1;
      }
      if (a[sortProperty] < b[sortProperty]) {
        return 1;
      }
      return 0;
    });
    setTopTeamsData(teams);
  }, [sortVariation, recentData]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenTeams = () => {
    setOpenTeams(true);
  };
  const handleCloseTeams = () => {
    setOpenTeams(false);
  };

  const toggleTopSort = () => {
    setSortVariation(!sortVariation);
  };

  const TopTeams: React.FC<{ limit: number; topTeamsList: any }> = ({
    limit,
    topTeamsList,
  }) => {
    return (
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Team</TableCell>
              <TableCell
                align="right"
                onClick={toggleTopSort}
                sx={{
                  textDecoration: !sortVariation ? "underline" : "none",
                  cursor: !sortVariation ? "inherit" : "pointer",
                }}
              >
                Experiments
              </TableCell>
              <TableCell
                align="right"
                onClick={toggleTopSort}
                sx={{
                  textDecoration: sortVariation ? "underline" : "none",
                  cursor: sortVariation ? "inherit" : "pointer",
                }}
              >
                Avg Variations
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topTeamsList.slice(0, limit).map((item: any) => (
              <TableRow
                key={item.team_name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {item.team_name}
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  {item.row_count}
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  {parseFloat(item.avg_variations).toFixed(1)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  const recentExperimentsList = (limit: number) => {
    return (
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Experiment name</TableCell>
              <TableCell>Team</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recentData.recent.slice(0, limit).map((item: any) => (
              <TableRow
                className="experiment-list-row"
                key={item.rule_id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={(e) => {
                  navigate(
                    `/results/${item.rule_id}?s=${new Date(
                      item.rule_started
                    ).getTime()}`
                  );
                }}
              >
                <TableCell component="th" scope="row">
                  {item.rule_name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.team_name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={7}>
        <Card>
          <CardContent>
            <Typography variant="h5">
              Recent experiments{" "}
              <HelpToolTip text="Experiments started during the last 30 days" />
            </Typography>
            {isLoaded ? recentExperimentsList(5) : <CenteredSpinner />}
            <Typography variant="body2" color="textSecondary" align="right">
              <Button
                disabled={recentData.recent.length < 6}
                onClick={() => {
                  interaction("more");
                  handleOpen();
                }}
              >
                More...
              </Button>
            </Typography>
          </CardContent>
        </Card>
        <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
          <DialogTitle>Live experiments</DialogTitle>
          <DialogContent>{recentExperimentsList(999)}</DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Grid item xs={5}>
        <Card>
          <CardContent>
            <Typography variant="h5">
              Top teams
              <HelpToolTip text="Top teams during the last 30 days, ranked on number of experiments or average number of variations" />
            </Typography>
            {isLoaded ? (
              <TopTeams limit={5} topTeamsList={topTeamsData} />
            ) : (
              <CenteredSpinner />
            )}
            <Typography variant="body2" color="textSecondary" align="right">
              <Button
                disabled={topTeamsData.length < 6}
                onClick={() => {
                  interaction("more");
                  handleOpenTeams();
                }}
              >
                More...
              </Button>
            </Typography>
          </CardContent>
        </Card>
        <Dialog
          open={openTeams}
          onClose={handleCloseTeams}
          maxWidth="xl"
          fullWidth
        >
          <DialogTitle>Top teams</DialogTitle>
          <DialogContent>
            <TopTeams limit={999} topTeamsList={topTeamsData} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseTeams}>Close</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default RecentExperiments;
